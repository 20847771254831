<template>
  <div class="app-container rule-list-component">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="jumpToDetail">添加规则</el-button>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:productKeySlot>
          <el-select
            v-model="dataset.advanceFilterConfig.productKey.value"
            collapse-tags
            filterable
            clearable
            placeholder=""
            style="width: 100%"
            @change="(value) => productChange(value)"
          >
            <el-option
              v-for="item in productKeyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:deviceNameSlot>
          <el-select
            v-model="dataset.advanceFilterConfig.deviceName.value"
            collapse-tags
            placeholder=""
            remote
            clearable
            filterable
            style="width: 100%"
            @focus="deviceNameFocus"
          >
            <el-option
              v-for="item in deviceNameOptions"
              :key="item.deviceName"
              :label="item.description"
              :value="item.deviceName"
            >
            </el-option>
          </el-select>
        </template>
      </finalTable>
    </div>
    <!-- 触发记录抽屉 -->
    <TriggerHistoryDrawer
      v-if="triggerDrawer.show"
      :show="triggerDrawer.show"
      :dataset="triggerDrawer.datas"
      @close="triggerDrawerClose"
    />
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import {
  findRuleRelation,
  findRulePage,
  deleteRuleById,
  updateRuleStatus,
} from "@/api/ruge/vlink/rule/rule";
import { getProductList } from "@/api/ruge/vlink/product/product";
import {
  findByDNOrDescription,
  findDevicePage,
} from "@/api/ruge/vlink/device/device";
import TriggerHistoryDrawer from "./release/components/triggerHistoryDrawer.vue";
export default {
  name: "ruleListComponent",
  components: {
    finalTable,
    TriggerHistoryDrawer,
  },
  watch: {
    rule: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val.listLoading);
      },
      deep: true,
    },
  },
  data() {
    return {
      triggerDrawer: {
        show: false,
        datas: {},
      },
      productKeyOptions: [],
      deviceNameOptions: [],
      deviceQueryParams: {
        current: 1,
        rowCount: 50,
        description: null,
        productKey: null,
      },
      resourceOptions: [],
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          {
            prop: "ruleName",
            label: this.$t("vlink.rule.ruleName"),
            width: "",
          },
          { prop: "ruleKey", label: this.$t("vlink.rule.ruleKey"), width: "" },
          {
            prop: "ruleScope",
            label: this.$t("vlink.rule.ruleScope"),
            width: "",
          },
          {
            prop: "ruleType",
            label: this.$t("vlink.rule.ruleType"),
            width: "",
          },
          {
            prop: "ruleStatus",
            label: this.$t("vlink.rule.ruleStatus"),
            width: "",
          },
          {
            prop: "creationDate",
            label: this.$t("commons.creationTime"),
            width: "",
          },
          { prop: "operation", label: "操作", width: "325" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          ruleName: {
            type: "input",
            label: "规则名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则名称",
            prefixIcon: "el-icon-search",
          },
          ruleKey: {
            type: "input",
            label: "规则标识码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入规则标识码",
            prefixIcon: "el-icon-search",
          },
          ruleScope: {
            type: "selector",
            label: "作用域",
            value: "",
            placeholder: "请选择作用域",
            actionType: "goSearch",
            optionList: [
              {
                label: "云端",
                value: "CLOUD",
              },
              {
                label: "边缘",
                value: "EDGE",
              },
            ],
          },
          ruleType: {
            type: "selector",
            label: "规则类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设规则类型",
            optionList: [
              {
                label: "联动规则",
                value: "linkage",
              },
              {
                label: "告警规则",
                value: "alarm",
              },
              {
                label: "时间表",
                value: "schedule",
              },
              {
                label: "数据流转",
                value: "serverSub",
              },
              {
                label: "设备通信",
                value: "deviceCall",
              },
            ],
          },
          ruleStatus: {
            type: "selector",
            label: "规则状态",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择设规则状态",
            optionList: [
              {
                label: "启用",
                value: "ENABLE",
              },
              {
                label: "禁用",
                value: "DISABLE",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          ruleName: {
            type: "aLink",
            actionType: "aLinkClick",
            targetType: "ruleList",
          },
          ruleScope: {
            type: "enumerationColumn",
            emuList: {
              CLOUD: "云端",
              EDGE: "边缘",
            },
          },
          ruleType: {
            type: "enumerationColumn",
            emuList: {
              linkage: "联动规则",
              alarm: "告警规则",
              schedule: "时间表",
              serverSub: "数据流转",
              deviceCall: "设备通信",
            },
          },
          ruleStatus: {
            type: "switch",
            actionType: "switchEvent",
          },
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "triggerHistory",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "触发记录",
              },
              {
                actionType: "iconClick",
                eventName: "releaseHistory",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "版本记录",
              },
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "edit",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "copy",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-edit",
                tooltips: "复制",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
                disableProp: "ruleStatus",
                disableValue: "ENABLE",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          productKey: {
            type: "slot",
            slotName: "productKeySlot",
            value: "",
            label: "产品",
            inline: true,
          },
          deviceName: {
            type: "slot",
            slotName: "deviceNameSlot",
            value: "",
            label: "设备",
            inline: true,
          },
          ruleName: {
            inline: true,
            value: "",
          },
          ruleScope: {
            inline: true,
            value: "",
          },
          ruleType: {
            inline: true,
            value: "",
          },
          ruleStatus: {
            inline: true,
            value: "",
          },
          creationDate: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      rule: {
        title: null,
        configTitle: null,
        total: 0,
        list: null,
        loading: false,
        listLoading: false,
        isEdit: false,
        listQuery: {
          current: 1,
          rowCount: 10,
          ruleName: null,
          ruleStatus: null,
          ruleScope: null,
          ruleType: null,
          startTime: null,
          endTime: null,
          productKey: null,
          deviceName: null,
        },
        multipleSelection: [],
        ruleDialogVisible: false,
        ruleConfigDialogVisible: false,
        createLoading: false,
      },
    };
  },
  created() {
    // 如果从产品返回，则需要回显上次的查询条件
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("rule_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.rule.listQuery = { ...this.rule.listQuery, ...tempObj };
        this.dataset.pageVO.current = tempObj.current;
        this.dataset.pageVO.rowCount = tempObj.rowCount;
        Object.keys(this.rule.listQuery).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.rule.listQuery[item];
          }
        });
        if (tempObj.productKey) {
          this.dataset.advanceFilterConfig.productKey.value =
            tempObj.productKey;
          this.productChange(tempObj.productKey);
        }
        if (tempObj.deviceName) {
          this.dataset.advanceFilterConfig.deviceName.value =
            tempObj.deviceName;
        }
        // 处理高级查询中的taglist和创建时间
        let dataTemp = [tempObj.startTime || null, tempObj.endTime || null];
        this.dataset.advanceFilterConfig.creationDate.value = dataTemp;
        if (tempObj.tagList && tempObj.tagList.length > 0) {
          this.dataset.advanceFilterConfig.tagList.value =
            tempObj.tagList.split(",");
        }
      }
    }
    this.getTableList();
    this.initOptions();
  },
  methods: {
    // remoteMethod(query) {
    //   if (query !== "") {
    //     this.loading = true;
    //     this.getDeviceList(query);
    //   } else {
    //     this.deviceNameOptions = this.resourceOptions;
    //   }
    // },
    deviceNameFocus() {
      if (!this.dataset.advanceFilterConfig.productKey.value) {
        this.deviceNameOptions = [];
      }
    },
    initOptions() {
      getProductList().then((res) => {
        this.productKeyOptions = res.map((item) => {
          return {
            label: item.productName,
            value: item.productKey,
          };
        });
      });
      // this.getDeviceList();
    },
    // getDeviceList(filter) {
    //   findByDNOrDescription({
    //     filter,
    //   }).then((res) => {
    //     if (!this.resourceOptions.length) {
    //       this.resourceOptions = res;
    //     }
    //     this.deviceNameOptions = res;
    //   });
    // },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getTableList();
    },
    // dealParams(datas) {
    //   if (datas.params.productKey) {
    //     datas.params.productKey = datas.params.productKey.join(",");
    //   }
    //   if (datas.params.deviceName) {
    //     datas.params.deviceName = datas.params.deviceName.join(",");
    //   }
    //   return datas;
    // },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.dealTime(datas);
        this.rule.listQuery = { ...this.rule.listQuery, ...datas.params };
        this.rule.listQuery.current = 1;
        this.dataset.pageVO.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.rule.listQuery.current = datas.params.current.page;
        this.rule.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "switchEvent") {
        this.updateRuleStatus(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.jumpToDetail("view", datas.row);
            break;
          case "edit":
            this.jumpToDetail("edit", datas.row);
            break;
          case "delete":
            this.deleteRule(datas.row);
            break;
          case "releaseHistory":
            this.$router.push({
              path: "/iot/rule/release",
              query: { ruleKey: datas.row.ruleKey },
            });
            break;
          case "triggerHistory":
            this.triggerDrawer.datas = datas.row;
            this.triggerDrawer.show = true;
            break;
          case "copy":
            this.jumpToDetail("copy", datas.row);
            break;
        }
      }
    },
    triggerDrawerClose() {
      this.triggerDrawer.datas = {};
      this.triggerDrawer.show = false;
    },
    productChange(value) {
      console.log("aaa-value", value);
      // 根据产品获取设备
      this.dataset.advanceFilterConfig.deviceName.value = "";
      this.deviceRemoteMethod(value);
    },
    deviceRemoteMethod(value) {
      if (value) {
        let params = this.deviceQueryParams;
        params.productKey = value;
        findDevicePage(params).then((res) => {
          this.deviceNameOptions = res.rows;
        });
      }
    },
    dealTime(datas) {
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.rule.listQuery.startTime = datas.params.creationDate[0]
          ? new Date(datas.params.creationDate[0]).getTime()
          : null;
        this.rule.listQuery.endTime = datas.params.creationDate[1]
          ? new Date(datas.params.creationDate[1]).getTime()
          : null;
        delete datas.params.creationDate;
      } else {
        this.rule.listQuery.startTime = null;
        this.rule.listQuery.endTime = null;
      }
      return datas;
    },
    jumpToDetail(type, row) {
      this.$router.push({
        path: "/iot/rule/detail",
        query: row
          ? {
              ruleKey: row.ruleKey,
              type,
            }
          : {},
      });
    },
    deleteRule(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          let param = {
            ruleId: row.ruleId,
            ruleType: row.ruleType,
          };
          deleteRuleById(param).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getTableList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    updateRuleStatus(row) {
      let param = {
        ruleId: row.ruleId,
        ruleStatus: row.ruleStatus,
        ruleType: row.ruleType,
      };
      updateRuleStatus(param)
        .then(() => {
          // 成功提示
          this.$message({
            message: this.$t("message.saveSuccess"),
            type: "success",
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        })
        .finally(() => {
          this.getTableList();
        });
    },
    getTableList() {
      let param = { ...this.rule.listQuery };
      sessionStorage.setItem("rule_list_query_params", JSON.stringify(param));
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.rule.listLoading = true;
      findRulePage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows || [];
          this.rule.listLoading = false;
        })
        .catch(() => {
          this.rule.listLoading = false;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.rule-list-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>